import { Icon } from "@iconify/react";

// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import React from "react";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.info.dark,
  backgroundColor: theme.palette.info.light,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.info.dark,
    0
  )} 0%, ${alpha(theme.palette.info.darker, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppCampaigns(member) {
  member = member?.data || member;
  const deduped = member?.cards?.reduce((acc, current) => {
    const x = acc.find((item) => item.testId === current.testId);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  const removed1xxxxx = deduped?.filter((campaign) => campaign.testId > 200000);
  const removedSpecific = removed1xxxxx?.filter(
    (campaign) => !["952741", "889905", "953489","1257838"].includes(campaign.testId)
  );
  const baOnly = removedSpecific?.filter((campaign) => campaign.baOnly).length;
  const totalEasy = removedSpecific?.filter(
    (campaign) => campaign.baOnly || campaign.difficulty === 1
  ).length;
  return (
    <Tooltip
      placement="right-start"
      title={
        <React.Fragment>
          <Typography variant="h6" color="inherit">
            Campaigns BA Only
          </Typography>
          {removedSpecific
            ?.filter((campaign) => campaign.baOnly)
            .sort((a, b) => a.testId - b.testId)
            .map((campaign) => (
              <Typography variant="body2" color="inherit">
                {campaign.testId}
              </Typography>
            ))}
          <Typography variant="h6" color="inherit">
            Other easy campaigns
          </Typography>
          {removedSpecific
            ?.filter(
              (campaign) => !campaign.baOnly && campaign.difficulty === 1
            )
            .sort((a, b) => a.testId - b.testId)
            .map((campaign) => (
              <Typography variant="body2" color="inherit">
                {campaign.testId}
              </Typography>
            ))}
        </React.Fragment>
      }
    >
      <RootStyle>
      <Typography variant="h6" color="inherit">
            Campaign achievement
          </Typography>
        <IconWrapperStyle>
          <Icon icon="fa-solid:hammer" width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">{`${baOnly || 0}`}</Typography>
        <Typography variant="h5">
          {"/ "}
          {totalEasy || 0}
        </Typography>
        <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
          campaigns achievement:{" "}
          {`${(
            (baOnly / (totalEasy)) * 100 || 0
          ).toFixed(0)}`}
          %
        </Typography>
      </RootStyle>
    </Tooltip>
  );
}
