import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import Page from "../components/Page";
import {
  AppTrelloQualityScore,
  AppActivityRate,
  AppCDC,
  AppClientActivity,
  AppNPS,
  AppCampaigns,
  AppCDCBA,
} from "../components/_dashboard/app";

import tseMembers from "../config/baMembers.json";
import exportTimeById from "../config/exportTimeById.json";
import { forEach } from "lodash";
import setConnectedData from "../layouts/SetConnectedData";

import locationURLs from "../config/location.json";

let tsesData = [];

if (
  !localStorage.getItem("user_trello_id") &&
  !localStorage.getItem("user_trello_photo") &&
  !localStorage.getItem("user_email")
) {
  setConnectedData();
}

const monthByQuarter = {
  Q3_2022: '["01/2022","02/2022","03/2022"]',
  Q4_2022: '["04/2022","05/2022","06/2022"]',
  Q1_2023: '["07/2022","08/2022","09/2022"]',
  Q2_2023: '["10/2022","11/2022","12/2022"]',
  Q3_2023: '["01/2023","02/2023","03/2023"]',
  Q4_2023: '["04/2023","05/2023","06/2023"]',
  Q1_2024: '["07/2023","08/2023","09/2023"]',
  Q2_2024: '["10/2023","11/2023","12/2023"]',
  Q3_2024: '["01/2024","02/2024","03/2024"]',
  Q4_2024: '["04/2024","05/2024","06/2024"]',
  Q1_2025: '["07/2024","08/2024","09/2024"]',
};

//here to change the quarter at loading
var months = monthByQuarter["Q1_2025"];
let fullName = "";
let nullDataTemplate = [
  {
    id: "000",
    fullName: "No Data",
    username: "nodata",
    cards: [
      {
        testId: "000000",
        startDate: "2021-10-22T07:57:30.711Z",
        difficulty: 2,
        timeDuration: 6.5,
        estimationRespected: false,
      },
    ],
    averageCdc: null,
    cdcTally: 0,
    averageDifficulty: 0,
    difficultyTally: 0,
    difficultyScore: 0,
    respectedEstimation: 0,
    respectedEstimationTally: 0,
    trelloWorkRate: 0,
    goalTrelloQualityScore: "0",
    goalRespectedEstimation: "0",
    goalActivityRate: "0",
    weightedWorkRate: 0,
    tqs: 0,
    achievement: 0,
  },
];

function getTSEJson(quarterData, tseMembers) {
  for (let key in quarterData) {
    let memberData = quarterData[key];
    let memberId = memberData.id;

    //console.log('memberData', memberData);
    //console.log('memberId', memberId);

    tseMembers.map((tse, index) => {
      if (tse.id === memberId) {
        // Initiate new entries
        memberData.averageCdc = 0;
        memberData.cdcTally = 0;
        memberData.averageDifficulty = 0;
        memberData.difficultyTally = 0;
        memberData.difficultyScore = 0;
        memberData.respectedEstimation = 0;
        memberData.respectedEstimationTally = 0;

        // Add specific data into the new array
        memberData.fullName = tse.brogglName;
        memberData.trelloWorkRate = tse.trelloWorkRate;
        memberData.goalTrelloQualityScore = tse.trelloQualityScore;
        memberData.goalRespectedEstimation = tse.timeDevEstimation;
        memberData.goalActivityRate = tse.activityRate;

        //CDC regulation
        memberData.cdcList = [];

        // Add datas on each card of the tse
        if (memberData?.cards) {
          for (const card of memberData.cards) {
            if (!card) continue;

            card.timeDuration = addTimePassedOnCard(card);

            if (card?.cdc) {
              memberData.averageCdc += card.cdc;
              memberData.cdcList.push(card.cdc);
              memberData.cdcTally++;
            }
            if (card?.difficulty) {
              memberData.averageDifficulty += card.difficulty;
              memberData.difficultyTally++;
              memberData.difficultyScore += card.difficulty;
            }

            if (card?.difficulty && card?.timeDuration) {
              if (card.difficulty == 1 && card.timeDuration < 4) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else if (card.difficulty == 2 && card.timeDuration < 8) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else if (card.difficulty == 4 && card.timeDuration < 12) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else if (card.difficulty == 5) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else {
                card.estimationRespected = false;
                memberData.respectedEstimationTally++;
              }
            }
          }

          memberData.respectedEstimation = memberData.respectedEstimation * 100;
          // console.log(`>=20`, memberData);
          let memberCDCTotal = 0;
          for (
            let i = +(memberData.cdcList.length * 0.05).toFixed(0);
            i <
            memberData.cdcList.length -
              +(memberData.cdcList.length * 0.05).toFixed(0);
            i++
          ) {
            memberCDCTotal += memberData.cdcList[i];
          }
          // console.log(
          //   'ancien cdc',
          //   memberData.averageCdc / memberData.cdcTally,
          // );
          // console.log('total CDC', memberCDCTotal);
          memberData.averageCdc =
            memberCDCTotal / memberData.cdcTally -
            +(memberData.cdcList.length * 0.1).toFixed(0);

          memberData.averageDifficulty =
            Math.round(
              (memberData.averageDifficulty / memberData.difficultyTally) * 100
            ) / 100;
          memberData.respectedEstimation =
            memberData.respectedEstimation /
            memberData.respectedEstimationTally;
        }

        // Add new complete entries for this TSE in the final array
        tsesData.push(memberData);
        //console.log('memberData', memberData);
        tsesData.workRateTally = tsesData.workRateTally
          ? tsesData.workRateTally + parseFloat(memberData.trelloWorkRate)
          : memberData.trelloWorkRate;
        tsesData.cdcTally = tsesData.cdcTally
          ? tsesData.cdcTally + memberData.cdcTally
          : memberData.cdcTally;
        tsesData.cdcAverage = tsesData.cdcAverage
          ? tsesData.cdcAverage + memberData.averageCdc
          : memberData.averageCdc;
        tsesData.difficultyTally = tsesData.difficultyTally
          ? tsesData.difficultyTally + memberData.difficultyTally
          : memberData.difficultyTally;
        tsesData.difficultyScoreTally = tsesData.difficultyScoreTally
          ? tsesData.difficultyScoreTally + memberData.difficultyScore
          : memberData.difficultyScore;
      }
    });
  }
}

function getTimeById(idTest) {
  return exportTimeById[idTest];
}

function addTimePassedOnCard(card) {
  let timePassed = getTimeById(card.testId);
  return timePassed;
}

function calculateTSEQuarterResults(tsesData) {
  let workRateTally = tsesData.workRateTally;
  let difficultyScoreTally = tsesData.difficultyScoreTally;
  let cdcAverage = tsesData.cdcAverage;

  tsesData.averageCdc = cdcAverage / tsesData.length;

  tsesData.map((tse) => {
    tse.weightedWorkRate = tse.trelloWorkRate / workRateTally;
    tse.tqs = tse.weightedWorkRate * difficultyScoreTally * 0.95;
    tse.achievement = (tse.difficultyScore / tse.tqs) * 100;

    tsesData.tqsTally = tsesData.tqsTally
      ? tsesData.tqsTally + tse.tqs
      : tse.tqs;
  });

  return tsesData;
}

const getQuarterData = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ""
      }/getMonthlyData?months=${months}`
    );
    // const response = await fetch(
    //   `${
    //     locationURLs[process.env.REACT_APP_URL]
    //   }/getMonthlyData?months=["01/2022", "02/2022", "03/2022"]`,
    // );
    let quarterData = await response.json();
    resolve(quarterData);
  });
};

const getActivityData = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ""
      }/getActivityRate?months=${months}&name=${fullName}`
    );
    //const response = await fetch(
    //  `${"http://localhost:3001"}/getMonthlyData?months=["01/2022", "02/2022", "03/2022"]`
    //);
    let activityData = await response.json();
    //console.log(activityData);
    resolve(activityData);
  });
};

function calculateActivityRate(activityData) {
  let totalHours = 0;
  let activityHours = 0;
  for (const month in activityData) {
    activityHours += activityData[month].activity;
    totalHours +=
      activityData[month].totalHours - activityData[month].offDays * 8;
  }

  return (activityHours / totalHours) * 100;
}

let trelloApiKey = "ae073b471b1634609d1c64de12390012";
// let trelloApiToken =
//   "ac471bdba69fd8432a13a0a01f972dc4b6a10af5f8b69005c953a46f6814b7a6";

const trelloRequestOptions = {
  method: "GET",
  headers: {
    Accept: "application/json",
  },
};

const getConnectedTse = function async(tsesData) {
  let connectedTseId = "";
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `https://api.trello.com/1/members/me?key=${trelloApiKey}&token=${localStorage.getItem(
        "user_token"
      )}`,
      trelloRequestOptions
    );
    const connectedUserData = await response.json();
    connectedTseId = connectedUserData.id;
    let finalData = tsesData.filter((tse) => tse.id === connectedTseId);
    //console.log(finalData);
    resolve(finalData);
  });
};

const hasTokenInLocalStorage = () => {
  return localStorage.getItem("user_token") !== null;
};

const hasTokenInUrl = () => {
  const token = window.location?.hash.replace("#token=", "");
  if (token !== "") {
    localStorage.setItem("user_token", token);
    return true;
  }
  return false;
};

const DashboardAppBA = () => {
  //  Check login status
  const navigate = useNavigate();
  if (!hasTokenInLocalStorage() && !hasTokenInUrl()) {
    navigate("/login", { replace: true });
  }

  const [connectedUserData, setConnectedUserData] = useState([]);
  const [quarterMonth, setQuarterMonth] = useState(months);
  const [tsesGlobalData, setTsesGlobalData] = useState([]);
  const [globalOKRData, setGlobalOKRData] = useState({
    "client activity": 0,
    detail: [],
  });
  const [globalNPS, setGlobalNPS] = useState({
    nps: 0,
  });

  useEffect(async () => {
    await fetch(
      `${locationURLs[process.env.REACT_APP_URL] || ""}/getGlobalOKR`,
      {
        method: "post",
        body: JSON.stringify({ name: "client activity", months: quarterMonth }),
        headers: { "Content-type": "application/json" },
      }
    ).then((response) =>
      response.json().then((json) =>
        setGlobalOKRData({
          "client activity": json.data,
          detail: json.detail,
        })
      )
    );
    await fetch(
      `${locationURLs[process.env.REACT_APP_URL] || ""}/getGlobalOKR`,
      {
        method: "post",
        body: JSON.stringify({ name: "nps" }),
        headers: { "Content-type": "application/json" },
      }
    ).then((response) =>
      response.json().then((json) => setGlobalNPS({ nps: json.data }))
    );
  }, [quarterMonth]);

  const handleQuarterChange = (event, newQuarter) => {
    months = newQuarter;
    setQuarterMonth(newQuarter);
    //console.log(months);
  };

  // console.log('dashboard app');
  // console.log(tseMembers);

  useEffect(() => {
    const loadTable = async () => {
      //debugger;
      tsesData = [];
      let quarterData = await getQuarterData();
      //console.log(quarterData);
      let tseJson = getTSEJson(quarterData, tseMembers);
      //console.log(tseJson);
      tsesData = calculateTSEQuarterResults(tsesData);
      // console.log(tsesData);
      let connectedTseData = await getConnectedTse(tsesData);
      //console.log('connecter', connectedTseData);

      //specific case of none data for the quarter
      if (connectedTseData.length == 0) {
        connectedTseData = nullDataTemplate;
      }

      let connectedUserData = connectedTseData[0];

      if (
        !sessionStorage.getItem("name") ||
        sessionStorage.getItem("name") == "No Data"
      ) {
        sessionStorage.setItem("name", connectedUserData.fullName);
      }

      //add activity rate
      if (
        sessionStorage.getItem("name") != null &&
        sessionStorage.getItem("name") != "No Data"
      ) {
        fullName = connectedUserData.fullName;
        let activityData = await getActivityData();
        let activityRate = calculateActivityRate(activityData);
        connectedUserData.activityRate = activityRate;
        //console.log(activityRate);
      }

      //set specific data on dashboard
      setTsesGlobalData(tsesData);
      setConnectedUserData(connectedUserData);
      // console.log(connectedUserData);
    };

    loadTable();
  }, [quarterMonth]);

  return (
    <Page title="Okr & Stats">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">OKRs</Typography>
        </Box>
        <Grid container spacing={3}>
          <Container maxWidth="xl">
            <Box sx={{ mb: 0.5 }}>
              <Typography variant="h5">{connectedUserData.fullName}</Typography>
            </Box>
            <Box component="div" sx={{ display: "flex", mb: 5 }}>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppCampaigns data={connectedUserData} />
              </Grid>
              <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                <AppCDCBA data={connectedUserData} />
              </Grid>
            </Box>
          </Container>
        </Grid>{" "}
      </Container>
      <div>Calculations:</div>
      <div>
        Activity rate: total hours in quarter / (total hours booked in Broggl
        (but NOT_BILLABLE) - number of hours of days off)
      </div>
      <div>
        CDC: number of days between the time the card is taken in charge by a
        TSE to the date the card is validated (top 5 and bottom 5% of the cards
        excluded)
      </div>
      <div>Global CDC: average of everyone's CDC</div>
      <div>
        Client activity: average of the client activity during each month of the
        quarter
      </div>
      <div>
        Campaigns: easy trello campaigns with at least 70% of time spent by BA.
        Campaign goes to the BA with the highest time on the card. Goal is to
        reach a percentage of all the easy cards done by only you.
      </div>
    </Page>
  );
};

export default DashboardAppBA;
