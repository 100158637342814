import { Icon } from '@iconify/react';

// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.dark,
  backgroundColor: theme.palette.info.light,
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.info.dark,
    0,
  )} 0%, ${alpha(theme.palette.info.darker, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppClientActivity({ data }) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon='fa-solid:hammer' width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant='h3'>{`${
        data['client activity']
          ? `${data['client activity'].toFixed(1)}%`
          : '0%'
      }`}</Typography>
      <Typography variant='h5'>
        {`> `}
        {`${80}`}
      </Typography>
      <Typography variant='subtitle2' sx={{ opacity: 0.72 }}>
        Client Activity achievement:{' '}
        {((data['client activity'] / 80) * 100).toFixed(1)}% (detail:{' '}
        {data.detail.map((month, index) => {
          if (month)
            return (
              <span>
                {index > 0 ? '/' : ''} {month}%{' '}
              </span>
            );
        })}
        )
      </Typography>
    </RootStyle>
  );
}
