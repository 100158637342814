import React, { useEffect, useState } from 'react';

import { Paper, Button, Grid } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
  GridOverlay,
  DataGrid,
  GridToolbar,
  GridApiContext,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

import brogglEntries from './../config/brogglJsonExampleQ3.json';
import boardMembers from './../config/trelloBoardMembers.json';
import tseMembers from './../config/tseMembers.json';

var trelloApiKey = 'ae073b471b1634609d1c64de12390012';
var trelloApiToken =
  'bb14d738b90a4f4e005e8750ece32ba1c68f009f3480803ecd46dafa7110514d';

const targetQuarter = 'Q3';

const trelloRequestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
  },
};

const tseBoard = {
  id: 'V1m7qGRV',
  liveCampaignListId: '5f4e942b023d0629e4cb91a1',
};

const archivedBoards = [
  'L6i0RY6d', // Q1-Q2 2021 : https://trello.com/b/L6i0RY6d/tsecsm-fy2021-q1-q2-archived
  'mH3UG0Ld', // Q3-Q4 2021 : https://trello.com/b/mH3UG0Ld/tsecsm-fy2021-q3-q4-archived
  'jVyEyZJq', // Q1-Q2 2022: https://trello.com/b/jVyEyZJq/tsecsm-fy2022-q1-q2-archived
  'Vo37uX5O', // Q3 2022: https://trello.com/b/Vo37uX5O/tsecsm-fy2022-q3-archived
];

const getLists = async (boardId) => {
  const response = await fetch(
    `https://api.trello.com/1/boards/${boardId}/lists?key=${trelloApiKey}&token=${trelloApiToken}`,
    trelloRequestOptions,
  );
  return await response.json();
};

const Loader = () => (
  <GridOverlay>
    <div style={{ position: 'absolute', top: 0, width: '100%' }}>
      <LinearProgress />
    </div>
  </GridOverlay>
);

// Get all board members from trelloBoardMmebers.json
// .filter() Only keep people who are TSE
// .map() Add fields to be opopulated later to display data
const getTses = () => tseMembers.filter((tse) => tse.active === true);

// Calculate the total number of hours registered under billable in Broggl
const computeBillable = (entries) =>
  entries
    ? entries.reduce((acc, entry) => {
        //console.log(acc);
        //console.log(entry)
        return acc + (entry.billable === 'BILLABLE' ? entry.duration : 0);
      }, 0)
    : 0;

// Calculate the percentage of billable hours in Broggl
const calculatePercentageBillable = function (totalAll, totalBillable) {
  var result =
    totalAll !== 0 ? (parseInt(totalBillable) / parseInt(totalAll)) * 100 : 0;
  return result.toFixed(1) + '%';
};

// Calculate the percentage of billable hours in Broggl
const calculatePercentageWithDayOff = function (
  totalAll,
  totalBillable,
  totalDayOff,
) {
  var result =
    totalAll !== 0
      ? (parseInt(totalBillable) / parseInt(totalAll - totalDayOff * 8)) * 100
      : 0;
  return result.toFixed(1) + '%';
};

// Calculate the total number of hours registered in Broggl
const computeAll = (entries) =>
  entries
    ? entries.reduce((acc, entry) => {
        return acc + entry.duration;
      }, 0)
    : 0;

// Filter Broggl entries and only keep days off (divide the result by 8 to display days and not hours)
const computeDayOff = (entries) =>
  entries
    ? entries.reduce((acc, entry) => {
        return acc + (entry.project === 'Day Off' ? entry.duration : 0);
      }, 0) / 8
    : 0;

const getSimplifiedName = (str) =>
  str.split(' ')[0] + ' .' + str.split(' ')[1].charAt(0).toUpperCase();

const getTseCardsCount = (cards, tseId, tseIds, tqsArray, tqsForTse) => {
  let solo = 0;
  let team = 0;
  let total = 0;
  let french = 0;
  let english = 0;
  let campaigns = 0;
  let feasibilities = 0;
  let scoreTQS = tqsForTse.score;
  let scoreTQSGlobal = tqsArray.global;

  // console.log(tqsArray)
  // console.log(tqsForTse)
  // console.log(cards)
  cards.forEach((card) => {
    // Check solo and multiple
    const tseIsInCardMemberList = card.idMembers.indexOf(tseId) > -1;
    const listContainsAnotherTse = card.idMembers.some(
      (idMember) => idMember !== tseId && tseIds.indexOf(idMember) > -1,
    );

    if (tseIsInCardMemberList && listContainsAnotherTse) team++;
    if (tseIsInCardMemberList && !listContainsAnotherTse) solo++;

    // Check labels
    const isFrench = card.labels.some((label) => label.name === 'FR');
    const isEnglish = card.labels.some((label) => label.name === 'EN');
    const isCampaign = card.labels.some(
      (label) => label.name === 'Test creation / correction',
    );
    const isFeasibility = card.labels.some(
      (label) => label.name === 'Time estimation / Feasibility analysis',
    );

    // Check all cards and check labels of all these cards
    if (tseIsInCardMemberList) {
      total++;
      if (isFrench) french++;
      if (isEnglish) english++;
      if (isCampaign) campaigns++;
      if (isFeasibility) feasibilities++;
    }

    // Trello Quality Score
    //console.log(card.idMembers)
    //console.log(tseId)
    if (card.idMembers.indexOf(tseId) != -1) {
      //console.log('card')

      var tqs = card.checklists.some(function (checklist) {
        // console.log('getChecklistData')
        // console.log(checklist)

        if (
          checklist.name ==
          'Feasibility analysis & estimated delivery date (by TSE)'
        ) {
          checklist.checkItems.forEach((item, index) => {
            if (item.state == 'complete') {
              // console.log("index " + index);
              //getNumberToIncrease depending on the complexity of the card
              var getNumberToIncrease = 0; //here we choose 1 points for low complexity, 2 for medium, 4 for hight and 5 for more
              if (item.name.indexOf('need validation') != -1) {
                getNumberToIncrease = 5;
              } else if (item.name.indexOf('High') != -1) {
                getNumberToIncrease = 4;
              } else if (item.name.indexOf('Medium') != -1) {
                getNumberToIncrease = 2;
              } else {
                getNumberToIncrease = 1;
              }
              scoreTQS = tqsForTse.score + getNumberToIncrease;
              scoreTQSGlobal = tqsArray.global + getNumberToIncrease;
            }
          });
        }
        //var getNumberToIncrease = getChecklistData(id);
      });

      tqsForTse.score = scoreTQS;
      tqsArray.global = scoreTQSGlobal;
    }
  });
  return {
    solo,
    team,
    total,
    french,
    english,
    campaigns,
    feasibilities,
    scoreTQSGlobal,
    scoreTQS,
  };
};
/*
const getTrelloQualityScore = (cards, tseId, tseIds) => {

}
*/

// Get list of TSE and return a mapped array with values for each column of the table
const getData = (tses, quarterCards) => {
  return new Promise(async (resolveGetData, reject) => {
    // We retrieve data from all sources
    const { details } = await getBrogglData();
    const completedCards = await getCompletedCards();

    // We can already identify some global informations about the cards
    const cardsWithoutDueDate = completedCards.filter(
      (card) => card.due === null,
    );
    // console.log(completedCards)

    //futur array of all tqs
    const tqsArray = [];
    tses.map((tse) => {
      var tqs = {
        id: tse.id,
        name: tse.fullName,
        score: 0,
      };
      tqsArray.push(tqs);
    });
    tqsArray.global = 0;

    const tseIds = tses.map((tse) => tse.id);
    const tsesData = tses.map((tse, index) => {
      const tseCardsCount = getTseCardsCount(
        quarterCards,
        tse.id,
        tseIds,
        tqsArray,
        tqsArray[index],
      );
      //const tseQualityScore = getTseQualityScore(quarterCards, tse.id, tseIds);
      const brogglBillable = computeBillable(details[tse.brogglName]);
      const brogglAll = computeAll(details[tse.brogglName]);
      const brogglDayOff = computeDayOff(details[tse.brogglName]);

      return {
        id: index,
        trelloID: tse.id,
        name: getSimplifiedName(tse.brogglName),
        soloCards: tseCardsCount.solo, // Cards completed alone in live column and in the last quarter board
        teamCards: tseCardsCount.team, // Cards completed with other tses in live column and in the last quarter board
        completedCards: tseCardsCount.total, // Sum of soloCards and teamCards
        feasibilityCards: tseCardsCount.feasibilities, // Cards of type feasability completed in the live column and in the last quarter board
        campaignCards: tseCardsCount.campaigns, // Cards of type campaign completed in the live column and in the last quarter board
        frenchCards: tseCardsCount.french, // Cards with the label french completed in the live column and in the last quarter board
        englishCards: tseCardsCount.english, // Cards with the label english completed in the live column and in the last quarter board
        brogglBillable: brogglBillable, // Total time registered in broggl with the mention billable
        brogglPercBillable: calculatePercentageBillable(
          brogglAll,
          brogglBillable,
        ), // Percentage of billable time registered in broggl
        brogglPercBillableWithDayOff: calculatePercentageWithDayOff(
          brogglAll,
          brogglBillable,
          brogglDayOff,
        ), // Percentage of billable time registered in broggl with day off
        brogglAll: brogglAll, // Total time registered in broggl
        brogglDayOff: brogglDayOff, // Days off registered in Broggl
        asktse: 0,
        trelloQualityScore: tseCardsCount.scoreTQS, // Trello Quality Score for the TSE
        trelloQualityScoreGlobal: tqsArray.global, // Trello Quality Score Global
        goalsTrelloQualityScore: tse.trelloQualityScore, //OKR Trello Quality Score
        goalsActivityRate: tse.activityRate, // OKR Activity Rate for the TSE
        goalsTimeDevEstimation: tse.timeDevEstimation, // OKR Activity Rate for the TSE
        goalsProject: tse.otherProject, // OKR Project for the TSE
      };
    });
    // console.log(tqsArray)
    resolveGetData({ tsesData, cardsWithoutDueDate, tqsArray });
  });
};

const getBrogglData = () => {
  return new Promise(async (resolve, reject) => {
    // /!\ We need to wait to have a domain because :
    //     - https://trello.abtasty.io/cardCounter does not accept request that comes from a domain other than trello.abtasty.io
    //     - We don't have a domain yet

    // Quick solution is to use hardcoded data (in this case: brogglEntries.json)
    resolve(brogglEntries);
  });
};

// Get completed
const getCompletedCards = () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `https://api.trello.com/1/lists/${tseBoard.liveCampaignListId}/cards?key=${trelloApiKey}&token=${trelloApiToken}`,
      trelloRequestOptions,
    );
    const cards = await response.json();
    resolve(cards);
  });
};

// Get quarter
const getQuarterCards = () => {
  return new Promise(async (resolveGetQuarterCards, reject) => {
    const lastQuarterBoardId = archivedBoards[archivedBoards.length - 1];
    let archivedBoardLists = await getLists(lastQuarterBoardId);
    let quarterFilteredListsIds = archivedBoardLists
      .filter((list) => list.name.indexOf(targetQuarter) > -1)
      .map((list) => list.id);

    quarterFilteredListsIds = [
      ...quarterFilteredListsIds,
      tseBoard.liveCampaignListId,
    ];

    const quarterListPromises = quarterFilteredListsIds.map((listId) => {
      return new Promise(async (resolveGetArchivedTseCards, reject) => {
        const response = await fetch(
          `https://api.trello.com/1/lists/${listId}/cards?key=${trelloApiKey}&token=${trelloApiToken}&checklists=all`,
          trelloRequestOptions,
        );
        const cards = await response.json();
        resolveGetArchivedTseCards(cards);
      });
    });

    await Promise.all(quarterListPromises).then((quarterListResults) => {
      const quarterCards = quarterListResults.flat(1);
      resolveGetQuarterCards(quarterCards);
    });
  });
};

const TseTeamReport = () => {
  const defaultCols = [{ headerName: '👤 Name', field: 'name', width: 140 }];

  const cardsCols = [
    { headerName: '🃏 Solo Cards', field: 'soloCards', width: 170 },
    { headerName: '🃏 Team Cards', field: 'teamCards', width: 180 },
    {
      headerName: '🃏 Completed Cards',
      field: 'completedCards',
      width: 210,
    },
    {
      headerName: '🃏 Feasibility Cards',
      field: 'feasibilityCards',
      width: 200,
    },
    {
      headerName: '🃏 Campaign Cards',
      field: 'campaignCards',
      width: 200,
    },
    {
      headerName: '🇫🇷 French Cards',
      field: 'frenchCards',
      width: 180,
    },
    {
      headerName: '🇬🇧 English Cards',
      field: 'englishCards',
      width: 190,
    },
  ];

  const brogglCols = [
    { headerName: '💸 Broggl - Billable', field: 'brogglBillable', width: 200 },
    { headerName: '⏲ Broggl - All', field: 'brogglAll', width: 200 },
    { headerName: '⏲ Broggl - Day off', field: 'brogglDayOff', width: 200 },
    { headerName: '📈 Activity Rate', field: 'brogglPercBillable', width: 200 },
    {
      headerName: '📈 Activity Rate with day off',
      field: 'brogglPercBillableWithDayOff',
      width: 290,
    },
  ];

  const otherCols = [{ headerName: '📥 Ask-tse', field: 'asktse', width: 200 }];

  const [columnsOpts, setColumnsOpts] = useState([
    ...defaultCols,
    ...cardsCols,
    ...brogglCols,
    ...otherCols,
  ]);
  const [tsesData, setTsesData] = useState([]);
  const [cardsWithoutDueDate, setCardsWithoutDueDate] = useState([]);
  const [tqsArray, setTqsArray] = useState([]);
  const [loading, setLoading] = useState(true);

  const [view, setView] = useState('all');

  // console.log(tsesData)

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    switch (view) {
      case 'all':
        setColumnsOpts([
          ...defaultCols,
          ...cardsCols,
          ...brogglCols,
          ...otherCols,
        ]);
        break;
      case 'cards':
        setColumnsOpts([...defaultCols, ...cardsCols]);
        break;
      case 'broggl':
        setColumnsOpts([...defaultCols, ...brogglCols]);
        break;
      case 'other':
        setColumnsOpts([...defaultCols, ...otherCols]);
        break;
      default:
        setColumnsOpts([
          ...defaultCols,
          ...cardsCols,
          ...brogglCols,
          ...otherCols,
        ]);
    }

    const loadTable = async () => {
      const tses = getTses();
      const quarterCards = await getQuarterCards();
      const { tsesData, cardsWithoutDueDate, tqsArray } = await getData(
        tses,
        quarterCards,
      );
      setCardsWithoutDueDate(cardsWithoutDueDate);
      setTqsArray(tqsArray);
      setTsesData(tsesData);
      setLoading(false);
    };

    loadTable();
  }, [view]);

  return [
    <Paper
      style={{
        display: 'none',
        height: '100%',
        width: '100%',
        marginBottom: '2rem',
        paddingBottom: '6rem',
      }}>
      <div style={{ padding: '1rem 1rem 0 1rem' }}>
        <Button onClick={() => setView('all')}>All</Button>
        <Button onClick={() => setView('cards')}>Cards</Button>
        <Button onClick={() => setView('broggl')}>Broggl</Button>
        <Button onClick={() => setView('other')}>asktse</Button>
      </div>
      <DataGrid
        style={{ margin: '1rem 1rem 0 1rem', padding: '1rem' }}
        loading={loading}
        columns={columnsOpts}
        rows={tsesData}
        components={{
          LoadingOverlay: Loader,
          Toolbar: GridToolbar,
        }}
      />
    </Paper>,
    <Grid
      container
      spacing={{ xs: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}>
      {tsesData.map((tse) => (
        <Grid item key={tse.trelloID} xs={12}>
          <Grid item className='name'>
            <h3 className='name'>{tse.name}</h3>
          </Grid>
          <Item className='container_okr tqs'>
            <h5> Trello Quality Score </h5>
            <span>{tse.trelloQualityScore}</span> /{' '}
            <span>{tse.goalsTrelloQualityScore} </span>
          </Item>
          <Item className='container_okr tqsGlobal'>
            <h5> Percentage Trello Quality Score </h5>
            <span>
              {((tse.trelloQualityScore * 100) / tqsArray.global).toFixed(2)}%
            </span>
            <span> (TQS global: {tqsArray.global}) </span>
          </Item>
          <Item className='container_okr activity_rate'>
            <h5> Activity Rate </h5>
            <span>{tse.brogglPercBillableWithDayOff}</span> /{' '}
            <span>{tse.goalsActivityRate}</span>
          </Item>
          <Item className='container_okr time_estimation'>
            <h5> Time Development Estimation </h5>
            <span></span> / <span>{tse.goalsTimeDevEstimation}</span>
          </Item>
          <Item className='container_okr Project'>
            <h5> Project </h5>
            <span>{tse.goalsProject}</span>
          </Item>
        </Grid>
      ))}
    </Grid>,
  ];
};

const getDaysBeforeNextQuarter = () => {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  let nextq;
  if (quarter === 4) {
    //nextq = new Date(today.getFullYear() + 1, 1, 1);
    nextq = new Date(today.getFullYear(), quarter * 3, 1);
  } else {
    nextq = new Date(today.getFullYear(), quarter * 3, 1);
  }
  const millis1 = today.getTime();
  const millis2 = nextq.getTime();
  const daydiff = (millis2 - millis1) / 1000 / 60 / 60 / 24;
  return Math.round(daydiff);
};

const getTrelloQualityScoreGlobal = () => {};

const MainBoardReport = () => {
  const [lists, setLists] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const lists = await getLists(tseBoard.id);
      setLists(lists);
    };
    getData();
  }, []);

  return (
    <Paper style={{ display: 'block' }}>
      <p style={{ marginBottom: '2rem' }}>
        There is {getDaysBeforeNextQuarter()} days before end of quarter
      </p>
      <div style={{ display: 'none' }}>
        {lists.map((list, index) => (
          <p key={index}>{list.name}</p>
        ))}
      </div>
    </Paper>
  );
};

const Dashboard = () => [<TseTeamReport />, <MainBoardReport />];

export default Dashboard;
