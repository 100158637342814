import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//
import Login from "./pages/Login";
import DashboardApp from "./pages/DashboardApp";
import Dashboard from "./pages/Dashboard";
import DashboardBA from "./pages/DashboardBA";
import DashboardAppBA from "./pages/DashboardAppBA";
import ObjectivesView from "./pages/ObjectivesView";

import getConnectedUserData from "./layouts/SetConnectedData";
import managerList from "./config/managerList.json";
import BAs from "./config/baMembers.json";

// ----------------------------------------------------------------------

export default function Router() {
  const loggedIn = () => {
    return (
      localStorage.getItem("user_token") !== "" &&
      localStorage.getItem("user_token") !== null
    );
  };

  if (loggedIn() && localStorage.getItem("user_trello_id")) {
    // console.log('logged in');
    if (location.href.includes("login")) location.pathname = "/dashboard/app";
  } else {
    getConnectedUserData();
  }

  const TestIfManager = () => {
    isManager = false;
    if (managerList.includes(localStorage.getItem("user_trello_id"))) {
      isManager = true;
    }
    return isManager;
  };

  const isBA = BAs.some((BA) => BA.id === localStorage.user_trello_id);

  const isSuperAdmin = [
    "563b59813f2d28b48cb95ee9",
    "5be1b1ce8575750d1318e884", // léon
    "5ac36829e05704b865343268",
    "62e7b9ec2696d37e00d55777",
    "5255564fee33367764001d62", // Rémi
  ].includes(localStorage.user_trello_id); // matthieu
  // localStorage.user_trello_id === '5be1b1ce8575750d1318e884'; // leon test
  // localStorage.user_trello_id === '5f5789b530d0240bcf23e371'//leo test

  var isManager = TestIfManager();
  //console.log("routes is manager " + isManager);

  const superAdminRoutes = [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "team", element: <Dashboard /> },
        { path: "teamBA", element: <DashboardBA /> },
        { path: "app", element: <DashboardApp /> },
        { path: "okr", element: <ObjectivesView /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "*", element: <Navigate to="/login" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  const managerRoutes = [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "team", element: <Dashboard /> },
        { path: "app", element: <DashboardApp /> },
        { path: "okr", element: <ObjectivesView /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "*", element: <Navigate to="/login" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  const guestRoutes = [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardApp /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "*", element: <Navigate to="/login" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
  const managerBARoutes = [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { path: "team", element: <DashboardBA /> },
        { path: "app", element: <DashboardApp /> },
        { path: "okr", element: <ObjectivesView /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "*", element: <Navigate to="/login" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];
  // const managerBARoutes = [
  //   {
  //     path: '/dashboard',
  //     element: <DashboardLayout />,
  //     children: [
  //       { element: <Navigate to='/dashboard/app' replace /> },
  //       { path: 'team', element: <DashboardBA /> },
  //     ],
  //   },
  //   {
  //     path: '/',
  //     element: <LogoOnlyLayout />,
  //     children: [
  //       { path: 'login', element: <Login /> },
  //       { path: '/', element: <Navigate to='/login' /> },
  //       { path: '*', element: <Navigate to='/login' /> },
  //     ],
  //   },
  //   { path: '*', element: <Navigate to='/404' replace /> },
  // ];

  const BARoutes = [
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "app", element: <DashboardAppBA /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "/", element: <Navigate to="/login" /> },
        { path: "*", element: <Navigate to="/login" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ];

  // var routesToUse = isManager ? managerRoutes : BARoutes;
  // const isBA = user.role === 'BA';
  // const isManager = user.role === 'manager';

  const routesToUse = isSuperAdmin
    ? superAdminRoutes
    : isBA
    ? isManager
      ? managerBARoutes
      : BARoutes
    : isManager
    ? managerRoutes
    : guestRoutes;
  // const routesToUse = isSuperAdmin
  //   ? superAdminRoutes
  //   : isBA
  //   ? isManager
  //     ? managerBARoutes
  //     : BARoutes
  //   : isManager
  //   ? managerRoutes
  //   : guestRoutes;
  return useRoutes(routesToUse);
}
