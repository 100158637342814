import React from "react";

var trelloApiKey = 'ae073b471b1634609d1c64de12390012';

const trelloRequestOptions = {
  method: "GET",
  headers: {
    Accept: "application/json",
  },
};

const getConnectedTse = function async() {

  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `https://api.trello.com/1/members/me?key=${trelloApiKey}&token=${localStorage.getItem(
        "user_token"
      )}`,
      trelloRequestOptions
    );
    const connectedUserData = await response.json();
    //console.log(connectedUserData);
    var connectedTseId = connectedUserData.id;
    var connectedTsePhoto = connectedUserData.avatarUrl;
    var connectedTseEmail = connectedUserData.email;
    // console.log(connectedUserData);
    localStorage.setItem('name', connectedUserData.fullName);
    localStorage.setItem("user_trello_id", connectedTseId);
    localStorage.setItem("user_trello_photo", connectedTsePhoto);
    localStorage.setItem("user_email", connectedTseEmail)
    //console.log("result connected data")
    resolve(connectedUserData);
  });
};

//getConnectedTse()

export default getConnectedTse;