import { Icon } from "@iconify/react";

// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";
import { filter } from "lodash";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
  minHeight: "100%",
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
  minHeight: "100%",
}));

// ----------------------------------------------------------------------

export default function AppCDC({ data }) {

  console.log(data);
  const filteredList = data?.cards
    ?.filter((card) => !!card.testId)
    ?.reduce((acc, current) => {
      const x = acc.find((item) => item.testId === current.testId);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, [])
    ?.filter(
      (campaign) =>
        campaign.testId > 200000 &&
        ![
          "946282",
          "946476",
          "946493",
          "946498",
          "946505",
          "946509",
          "1099853",
          "951205",
          "1257838"
        ].includes(campaign.testId)
    )
    ?.sort((a, b) => a.baCdc - b.baCdc);

  //remove 5% each side
  console.log('filteredList',filteredList);
  const filteredListLength = filteredList?.length;
  const filteredList5Percent = Math.round(filteredListLength * 0.05);
  const filteredList5PercentRemoved = filteredList?.slice(
    filteredList5Percent,
    filteredListLength - filteredList5Percent
  );

  const averageCdc =
    filteredList5PercentRemoved?.reduce((acc, current) => {
      return acc + current.baCdc;
    }, 0) / filteredList5PercentRemoved?.length;

  return (
    <Tooltip
      placement="right-start"
      title={
        <React.Fragment>
          <Typography variant="h6" color="inherit">
            CDC (for BAs)
          </Typography>
          {filteredList?.map((campaign) => (
            <Typography variant="body2" color="inherit">
              {`Campaign ID: ${campaign.testId}. CDC: ${campaign.baCdc} ${
                campaign.cdc <= 1 ? "day" : "days"
              }.`}
            </Typography>
          ))}
        </React.Fragment>
      }
    >
      <RootStyle>
      <Typography variant="h6" color="inherit">
            CDC (for BAs)
          </Typography>
        <IconWrapperStyle>
          <Icon icon="bi:clock-fill" width={24} height={24} />
        </IconWrapperStyle>
        <Typography variant="h3">
          {`${averageCdc ? averageCdc.toFixed(1) : 0}`} days
        </Typography>
      </RootStyle>
    </Tooltip>
  );
}
