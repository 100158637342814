import managerList from '../config/managerList.json';

const TestIfManager = () => {
  isManager = false;
  if (managerList.includes(localStorage.getItem('user_trello_id'))) {
    isManager = true;
  }
  return isManager;
};

var isManager = TestIfManager();
// console.log(isManager)

export default isManager;
